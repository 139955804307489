import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Dimensions} from "@/components/globalModels/Dimensions";


export function ExpressFast() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    orderObj: null,
    isNewDimensions: false,
    changedDimensions: false,
    reCreateLabel: false,
    OldDimensions: new Dimensions(),
    Dimensions: new Dimensions(),
  }

  this.validation = {

  }

  this.validationTranslate = {

  }

  this.validationTxt = {

  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getOrderObj = () => {
    return this.data.orderObj
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setOrderObj = (val) => {
    this.data.orderObj = val
  }
  this.setIsNewDimensions = (val) => {
    this.data.isNewDimensions = val
  }
  this.setChangedDimensions = (val) => {
    this.data.changedDimensions = val
  }

}
