import {ExpressFastOrders} from "@/components/modules/ExpressFastOrdersModule/models/ExpressFastOrders";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

export const expressFastOrdersMixin = {

	mixins: [routeFilter],

	methods: {

		exportExpressFastOrders(id) {

			this.$store.dispatch('getExportExpressFastOrders', id ? id : this.EFO.data.id).then(response => {
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.createDownloadLink({data: response.data, name: `Express report.xlsx`, type: 'xlsx'})
						this.openNotify('success', 'common_notificationRecordCreated')
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		reload() {
			this.loaded = false
			this.EFO = new ExpressFastOrders()
			this.initExpressFastOrdersEdit()
		},

		createLabel(createLabel, edit, order) {

			if(!order?.order_express_fast_order) return this.openNotify('error', 'common_notificationUndefinedError')

			let foundElem = order.order_express_fast_order.elements.find(elem => {return elem?.order_express_id === createLabel})

			if(!foundElem) return this.openNotify('error', 'common_notificationUndefinedError')

			return this.$store.dispatch('reCreateLabelExpressFastOrders', {idReport: order?.order_express_fast_order?.id, idElement: foundElem?.id}).then(response => {
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.openNotify('success', 'common_notificationRecordCreated')
						this.reload()
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})


		},

		saveExpressFastOrders(edit = false, createLabel = false, reload = false) {

			// if (!this.FO.firstValidationAdmin()) return

			console.log(createLabel);

			let data = this.EFO.prepareSave()

			let saveType = 'createExpressFastOrders'

			if (edit) {
				data = {
					id: this.EFO.getId(),
					data: data
				}
				saveType = 'updateExpressFastOrders'
			}

			return this.$store.dispatch(saveType, data).then(response => {
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if(this.getRespData(response)?.warnings) {
							this.openNotify('warn', {
								txtServer: this.getRespData(response)?.warnings[0]
							})
						}

						if(createLabel) {
							this.createLabel(createLabel, edit, this.getRespData(response))
							return
						}

						if(reload && !edit) {
							this.openNotify('success', 'common_notificationRecordCreated')
							this.$router.push(this.$store.getters.GET_PATHS.reportsExpressFastOrdersEdit + '/' + this.getRespData(response)?.order_express_fast_order?.id)
							return
						}

						if(reload && edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
							this.reload()
							return
						}

						// if (createLabels) {
						// 	let id = this.getRespData(response)?.order_id
						// 	this.createLabelsForFastOrders(id, false)
						// }
						// else {
						// }

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}

						this.$router.push(this.$store.getters.GET_PATHS.reportsExpressFastOrders)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},


	}
}